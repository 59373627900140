import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import "typeface-lato"

const ImpressumPage = () => {
  return (
    <>
      <SEO title="Impressum" />

      <Layout>
        <div className="impressum-wrapper">
          <h1>Impressum</h1>
          <h2>Rolf Schick GmbH</h2>
          <p>Mäuswedel 13</p>
          <p>74223 Flein</p>
          <div className="information-flex-box">
            <div>
              <p>Telefon</p>
              <p>07131 57 46 56</p>
            </div>
            <div>
              <p>Fax</p>
              <p>07131 57 89 26</p>
            </div>
            <div>
              <p>E-Mail</p>
              <p>
                <a href="mailto:info@derfeineteig.de">info@derfeineteig.de</a>
              </p>
            </div>
            <div>
              <p>Internet</p>
              <p>
                <Link to="/">https://www.derfeineteig.de</Link>
              </p>
            </div>
          </div>
          <div className="information-flex-box">
            <div>
              <p className="more-left">
                Vertretungsberechtigter
                <br />
                Geschäftsführer
              </p>
              <p>Theresia Schick</p>
            </div>
            <div>
              <p className="more-left">Zuständige Kammer</p>
              <p>IHK Heilbronn-Franken</p>
            </div>
            <div>
              <p className="more-left">Handelsregister</p>
              <p>AG Heilbronn HRB 102328</p>
            </div>
            <div>
              <p className="more-left">Umsatzsteuer-Ident Nr.</p>
              <p>DE14 5781 745</p>
            </div>
          </div>
          <h2>Haftungshinweis</h2>
          <p>
            Links, die von dieser Homepage zu anderen Internet-Seiten führen,
            sind eine Gefälligkeit gegenüber dem Internet-Nutzer. Die Benutzung
            der Links geschieht auf eigene Gefahr. Trotz sorgfältiger
            inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
            externer Links. Für den Inhalt der verlinkten Seiten sind
            ausschließlich deren Betreiber verantwortlich. Links von anderen
            Internet-Angeboten auf diese Homepage oder ihre Unterseiten sind
            ohne weitere Informationen oder Anfragen erlaubt, sofern sie das
            Anliegen dieser Hoempage unterstützen und sie oder eine ihrer
            Unterseiten im gesamten bisherigen oder einem neuen Browserfenster
            zeigen. Aufrufe in einem Frame-Teilfenster, die so die verlinkte
            Seite dieser Website als Teil der fremden Internet-Seite erscheinen
            lassen, sind nicht statthaft.
          </p>
          <h2>Copyright</h2>
          <p>
            Die Inhalte dieser Website sind urheberrechtlich geschützt. Jede
            Vervielfältigung, Vorführung, Sendung, Vermietung und/oder Leihe der
            Website oder einzelner Inhalte ist ohne Einwilligung des
            Rechteinhabers untersagt und zieht straf- oder zivilrechtliche
            Folgen nach sich. Alle Rechte bleiben vorbehalten. Der Rechteinhaber
            behält sich das Recht vor, jederzeit und ohne vorherige Ankündigung
            Verbesserungen und Änderungen an der Website oder einzelner Inhalte
            vorzunehmen.
          </p>
          <h2>Genutze Frameworks</h2>
          <p>
            Für diese Homepage wird PHPMailer genutzt. Dies steht unter der LGPL
            Lizenz.
          </p>
          <p>
            <a
              href="https://fontawesome.com/icons/bars?style=solid"
              target="_blank"
              rel="noopener noreferrer"
            >
              Menu Bars
            </a>{" "}
            and{" "}
            <a
              href="https://fontawesome.com/icons/times?style=solid"
              target="_blank"
              rel="noopener noreferrer"
            >
              Close Button
            </a>{" "}
            Icons are under Creative Common Licence
          </p>
        </div>
      </Layout>
    </>
  )
}

export default ImpressumPage
